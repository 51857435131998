<template>
  <b-row>    
    <b-col md="12">
      <b-form-group label="ROLES" description="Todos los usuarios que tengan el/los roles seleccionados, recibiran notificación">
        <v-select :options="arr.select.roles" v-model="crud.form.role_notification" placeholder="Roles" :multiple="true" :select-on-tab="true" @input="getNotifications()"></v-select>
      </b-form-group>
    </b-col>                         
    <b-col md="12">
      <b-form-group label="STAFF"  description="Todos los usuarios seleccionados, recibiran notificación">
        <v-select :options="arr.select.staff" v-model="crud.form.staff_notification" placeholder="Staff" :multiple="true" :select-on-tab="true" @input="getNotifications()"></v-select>
      </b-form-group>
    </b-col>                                                    
    <b-col md="12">
      <b-form-group label="EMAILS" description="Todos los emails cargados, van a recibir notificación por email">
        <b-form-tags                
          v-model="crud.form.email_notification"
          separator=" ,"
          placeholder="Ingrese correos separados por enter, espacio o coma"
          @input="getNotifications()"
        ></b-form-tags>            
      </b-form-group>
    </b-col> 
  </b-row>  
</template>
<script>
  import serviceAPI from './services'  

  export default {
    props: {
      role_notification: {
        type: Array,
        default: null,
      },      
      staff_notification: {
        type: Array,
        default: null,
      },      
      email_notification: {
        type: Array,
        default: null,
      },                  
    },
    data: () => {
      return {
        crud: {
          form: {
            role_notification: [],
            staff_notification: [],
            email_notification: []
          }
        },
        arr: {
          select: {
            roles: [],
            staff: []
          }
        }
      }
    },     
    mounted() {
      this.getRoles()
      this.getStaff()

      if(this.email_notification) {
        this.crud.form.email_notification = this.email_notification
      }        
    },
    methods: {
      getStaff() {
        serviceAPI.obtenerStaff().then(response => {
          var data = response.data    

          data.forEach(element => {              
            this.arr.select.staff.push({code: element.id, label: element.name})
          });  
          
          if(this.staff_notification) {
            this.staff_notification.forEach(element => {
              this.crud.form.staff_notification.push({code: element, label: this.getName('staff',element)})
            });        
          }              
        })
      },  
      getRoles() {
        serviceAPI.obtenerRoles().then(response => {
          var data = response.data    

          data.forEach(element => {              
            this.arr.select.roles.push({code: element.id, label: element.name})
          });     
          
          if(this.role_notification) {
            this.role_notification.forEach(element => {
              this.crud.form.role_notification.push({code: element, label: this.getName('roles',element)})
            });        
          }             
        })
      },        
      getNotifications() {
        this.$emit('get-notifications', this.crud.form)
      },
      getName(type, code) {
        var name = ''
        if(type=='roles') {
          this.arr.select.roles.forEach(element => {            
            if(element.code == code) {
              name = element.label
            }
          });
        }
        if(type=='staff') {
          this.arr.select.staff.forEach(element => {            
            if(element.code == code) {
              name = element.label
            }
          });
        }        
        return name
      }
    }
  }
</script>
