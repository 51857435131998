import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerServidor() {
    var url = ConfigAPI.baseURL + "instances-server" + Session.getToken();
    return instance.get(url);
  },
  mostrarServidor(id) {
    var url = ConfigAPI.baseURL + "instances-server/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarServidor(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "instances-server" + Session.getToken();
    return instance.post(url,params);
  },
  editarServidor(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "instances-server/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarServidor(id) {
    var url = ConfigAPI.baseURL + "instances-server/"+ id + Session.getToken();
    return instance.delete(url);
  }, 
  filtrarServidor(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "instances-server/filter/query" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);
  },  
  commandActionServidor(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "instances-server/commands/action" + Session.getToken();
    return instance.post(url,params);
  },  
  
  obtenerSistema() {
    var url = ConfigAPI.baseURL + "instances-system" + Session.getToken();
    return instance.get(url);
  },
  mostrarSistema(id) {
    var url = ConfigAPI.baseURL + "instances-system/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarSistema(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "instances-system" + Session.getToken();
    return instance.post(url,params);
  },
  editarSistema(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "instances-system/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarSistema(id) {
    var url = ConfigAPI.baseURL + "instances-system/"+ id + Session.getToken();
    return instance.delete(url);
  }, 
  filtrarSistema(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "instances-system/filter/query" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);
  },  
  commandActionSistema(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "instances-system/commands/action" + Session.getToken();
    return instance.post(url,params);
  },    
  commandBlockedSistema(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "instances-system/commands/blocked" + Session.getToken();
    return instance.post(url,params);
  }, 

  obtenerCategories() {
    var url = ConfigAPI.baseURL + "instances-categories" + Session.getToken();
    return instance.get(url);
  },
  mostrarCategories(id) {
    var url = ConfigAPI.baseURL + "instances-categories/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarCategories(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "instances-categories" + Session.getToken();
    return instance.post(url,params);
  },
  editarCategories(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "instances-categories/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarCategories(id) {
    var url = ConfigAPI.baseURL + "instances-categories/"+ id + Session.getToken();
    return instance.delete(url);
  }, 
  filtrarCategories(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "instances-categories/filter/query" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);
  },    

  filtrarServidorLog(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "instances-server-log/filter/query" + Session.getToken()
    return instance.post(url,params);
  },    

  obtenerClientes() {
    var url = ConfigAPI.baseURL + "customers" + Session.getToken();
    return instance.get(url);
  },  
  obtenerStaff() {
    var url = ConfigAPI.baseURL + "staff" + Session.getToken();
    return instance.get(url);    
  },  
  obtenerRoles() {
    var url = ConfigAPI.baseURL + "roles" + Session.getToken();
    return instance.get(url);    
  },  
}

export default servicesAPI;